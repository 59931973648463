export default {
  GET_OFFICIAL: state => {
    // get official information of app (email / phone / etc.)
    // see. store.state.app.official
    let official = state.official
    let currentYear = new Date().getFullYear()

    return {
      email: 'constlet@proton.me',
      watermark: `© ${currentYear} ${official.companyName}`,
      phone: official.phone,
      rights: state.constlet.inEnglish
        ? 'All rights reserved.'
        : 'Kaikki oikeudet pidätetään.'
    }
  },

  GET_BREAKPOINTS: state => {
    const windowWidth = state.window.width
    const breakpoints = state.ui.breakpoints
    const classes = ['s', 'm', 'l', 'xl']
    let current = 'm'

    for (let i = 0; i <= breakpoints.length - 1; i++) {
      if (windowWidth < breakpoints[i]) {
        current = classes[i]
        break
      } else if (i === breakpoints.length - 1 && windowWidth >= breakpoints[i]) {
        current = classes[breakpoints.length]
      }
    }
    
    return current
  }
};

// Example:
// GETTER_NAME: (state, getters) => {}
// ...or as method-style access:
// GETTER_NAME: (state, getters) => (param) => {}
