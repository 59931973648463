/* eslint-disable no-console */
import api_actions from "./api_actions"

// THIS FILE INSTALLS API PLUGIN FOR VUE
export default {
  install(Vue, options) {
    /* if (options === "firebase") {
      console.log("using firebase");
    } */
    Vue.prototype.$api = api_actions;
  }
};
