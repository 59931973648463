<template>
  <!-- outer wrapper -->
  <div class="base-content-wrapper-new" :style="styling.outer">
    <!-- inner wrapper -->
    <div class="base-content-wrapper-inner" :style="styling.inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'baseContentWrapperNew',

  props: {
    compensateNavTop: {
      type: Boolean,
      default: false
    },
    maxWidth: String,
    resetMaxWidth: {
      type: Boolean,
      default: false
    },
    /* resetPadding: {
      type: [Boolean, String],
      default: false,
      validator: prop => {
        if (typeof prop === 'string' && (prop === 'x' || prop === 'y' || prop === 'xy')) {
          return true
        } else if (typeof prop === 'boolean') {
          return true
        }
      }
    } */
  },

  computed: {
    ...mapState('app', ['ui', 'window']),

    styling() {
      let windowWidth = this.window.width// + 'px'
      let maxWidth = this.ui.contentMaxWidth// + 'px'
      let defaultPadding = this.ui.contentPadding// + 'px'
      let paddingX = defaultPadding
      //let paddingY = defaultPadding
      let bp1 = 500
      let bp2 = 800

      /* if (this.resetPadding && typeof this.resetPadding === 'boolean') {
        paddingX = false
        paddingY = false
      } else if (this.resetPadding && typeof this.resetPadding === 'string') {
        if (this.resetPadding === 'x' || this.resetPadding === 'xy') { paddingX = false }
        if (this.resetPadding === 'y' || this.resetPadding === 'xy') { paddingY = false }
      } */

      if (windowWidth > bp2) { paddingX = defaultPadding * 3 }
      else if (windowWidth > bp1) { paddingX = defaultPadding * 2 }

      if (this.resetMaxWidth) { maxWidth = 'none' }
      else if (this.maxWidth) { maxWidth = this.maxWidth }
      else { maxWidth = maxWidth + 'px' }

      return {
        outer: {
          paddingTop: this.compensateNavTop ? this.ui.navTopHeight + 'px' : false,
          //paddingLeft: this.GET_NAV_LEFT_DIMENSIONS // NOTE!: OTA KÄYTTÖÖN KUN TARPEELLISTA!!!
          paddingLeft: paddingX + 'px',
          paddingRight: paddingX + 'px'
        },
        inner: {
          maxWidth: maxWidth,
          //paddingLeft: paddingX,
          //paddingRight: paddingX,
          //paddingTop: paddingY,
          //paddingBottom: paddingY
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.base-content-wrapper-new {
  width: 100%;
  //@extend %transition--ui-default; // NOTE!: LISÄÄ KUN TARPEELLISTA!!!
}
.base-content-wrapper-inner {
  position: relative;
  margin: 0 auto;
  height: 100%;
}
</style>
