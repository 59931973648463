<template>
  <div class="base-textarea" :class="classing" :style="[styling, mixinMargins]">
    <label v-if="label">
      {{ this.label }}
      <span v-if="required"> (required)</span>
    </label>

    <textarea
      ref="textarea"
      :value="value"
      @input="emitInput"
      @focus="toggleFocus(true)"
      @blur="toggleFocus(false)"
      :rows="rows"
      :placeholder="$attrs.placeholder"
      :maxlength="maxLength"
    ></textarea>
  </div>
</template>

<script>
// TODOS:
// PROP: ANNA MAHDOLLISUUS RAJOITTAA MERKKIMÄÄRÄÄ, ESIM. 'max. 2000 characters'
import { sizing, margins } from '@/utils/mixins'

export default {
  name: 'baseTextarea',

  mixins: [sizing, margins],

  props: {
    value: String,
    rows: {
      type: Number,
      default: 3
    },
    label: String,
    required: Boolean,
    maxLength: Number
  },

  data () {
    return {
      focused: false,
      mixinSizeCategories: { s: 7, m: 8, l: 9, xl: 10 },
    }
  },

  mounted () {
    let ref = this.$refs.textarea

    function resize () {
      ref.style.height = 'auto'
      ref.style.height = ref.scrollHeight + 'px'
    }

    // 0-timeout to get the already changed text
    function delayedResize () {
      window.setTimeout(resize, 0)
    }

    this.observe(ref, 'change', delayedResize)
    this.observe(ref, 'cut', delayedResize)
    this.observe(ref, 'paste', delayedResize)
    this.observe(ref, 'drop', delayedResize)
    this.observe(ref, 'keydown', delayedResize)

    resize()
  },

  methods: {
    observe (element, event, handler) {
      if (window.attachEvent) {
        // this is only for IE8- (if any problems occur, it's possible to get rid of)
        element.attachEvent('on' + event, handler)
      } else {
        element.addEventListener(event, handler, false)
      }

      this.$on('hook:beforeDestroy', () => {
        element.removeEventListener(event, handler)
      })
    },

    toggleFocus (boolean) {
      this.focused = boolean
    },

    emitInput () {
      this.$emit('input', this.$refs.textarea.value)
    }
  },

  computed: {
    classing() {
      return {
        focused: this.focused
      }
    },

    styling() {
      return {
        fontSize: this.mixinSizing
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$textarea-color: $app-color--input;
$textarea-color--label: $app-color--input-label;
$textarea-color--border: $app-color--input-border;
$textarea-color--bg: $app-color--theme;
$textarea-color--placeholder: $app-color--input-placeholder;
$textarea-color--required: $app-color--error;

.base-textarea {
  // font-size: ; // see: this.styling

  label {
    display: block;
    margin-bottom: 0.3em;
    font-size: 0.9em;
    font-weight: 500;
    color: $textarea-color--label;
    span { color: $textarea-color--required; }
  }

  textarea {
    overflow: hidden;
    outline: 0;
    resize: none;
    transition: all 0.3s ease;
    width: 100%;
    //border: 1px solid lighten(desaturate($textarea-color--border, 30%), 30%);
    border: 1px solid $textarea-color--border;
    border-radius: 3px;
    padding: 0.6rem;
    font-size: inherit;
    line-height: 1.4em;
    letter-spacing: 0.05em;
    color: $textarea-color;
    &::placeholder { // not supported in all browsers, but behaves as regular text content if not
      font-family: $font-set--sans;
      font-size: inherit;
      font-weight: 500;
      color: $textarea-color--placeholder;
    }
  }
  //&.focused textarea { border: 1px solid  $textarea-color; }
}
</style>
