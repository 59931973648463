<template>
  <!-- outer wrapper -->
  <div class="base-content-wrapper" :style="styling.outer">
    <!-- inner wrapper -->
    <div class="base-content-wrapper-inner" :style="styling.inner">
      <slot></slot>
    </div>
  </div>
</template>

<script>
//import { mapState, mapGetters } from "vuex";
import { mapState } from 'vuex';

export default {
  props: {
    compensateNavTop: {
      type: Boolean,
      default: false
    },
    maxWidth: String,
    resetMaxWidth: {
      type: Boolean,
      default: false
    },
    resetPadding: {
      type: [Boolean, String],
      default: false,
      validator: prop => {
        if (typeof prop === 'string' && (prop === 'x' || prop === 'y' || prop === 'xy')) {
          return true
        } else if (typeof prop === 'boolean') {
          return true
        }
      }
    }
  },

  computed: {
    ...mapState('app', ['ui']),
    //...mapGetters('app', ['GET_NAV_LEFT_DIMENSIONS']), // NOTE!: OTA KÄYTTÖÖN KUN TARPEELLISTA!!!

    styling() {
      let defaultPadding = this.ui.contentPadding + 'px'
      let paddingX = defaultPadding, paddingY = defaultPadding
      let maxWidth

      if (this.resetPadding && typeof this.resetPadding === 'boolean') {
        paddingX = false
        paddingY = false
      } else if (this.resetPadding && typeof this.resetPadding === 'string') {
        if (this.resetPadding === 'x' || this.resetPadding === 'xy') { paddingX = false }
        if (this.resetPadding === 'y' || this.resetPadding === 'xy') { paddingY = false }
      }

      if (this.resetMaxWidth) {
        maxWidth = 'none'
      } else if (this.maxWidth) {
        maxWidth = this.maxWidth
      } else maxWidth = this.ui.contentMaxWidth + 'px'

      return {
        outer: {
          paddingTop: this.compensateNavTop ? this.ui.navTopHeight + 'px' : false,
          //paddingLeft: this.GET_NAV_LEFT_DIMENSIONS // NOTE!: OTA KÄYTTÖÖN KUN TARPEELLISTA!!!
          paddingLeft: 0
        },
        inner: {
          maxWidth: maxWidth,
          paddingLeft: paddingX,
          paddingRight: paddingX,
          paddingTop: paddingY,
          paddingBottom: paddingY
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.base-content-wrapper {
  width: 100%;
  //@extend %transition--ui-default; // NOTE!: LISÄÄ KUN TARPEELLISTA!!!
}
.base-content-wrapper-inner {
  position: relative;
  margin: 0 auto;
}
</style>
