<template>
  <component
    :is="tag"
    class="base-title"
    :class="classing"
    :style="[styling, mixinMargins]"
  >
    <slot>{{ this.editableInnerContent }}</slot>
  </component>
</template>

<script>
// TODOS:
// CLASS/JS: lisää hoveriin tooltip
// JS: lisää truncate / wordwrap / ...
// MIXIN/JS: lisää fontin dynaaminen skaalautuvuus viewportin koon perusteella!!! (variaatio s/m/l/xl -kokoihin!)

//import { truncate } from '@/utils/truncate'
import { sizing, margins, dynamicStyleSet, editableContent } from '@/utils/mixins'

export default {
  name: "baseTitle",

  mixins: [sizing, margins, dynamicStyleSet, editableContent],

  props: {
    tag: {
      type: String,
      default: "h2"
    },
    active: {
      validator: prop => typeof prop === "boolean" || prop === null,
      default: null
    },
    center: {
      type: Boolean,
      default: false
    },
    break: {
      type: Boolean,
      default: false
    },
    truncate: {
      type: Boolean,
      default: false
    }
    /* dynamic: { // Sizing is based on ui break points // TEE/LISÄÄ TÄMÄ MYÖHEMMIN UUDELLEEN?!?!
      type: Boolean,
      default: false
    }, */
    /* lines: {
      type: Number
    }, */
  },

  data() {
    return {
      mixinSizeCategories: { s: 9, m: 13, l: 17, xl: 24 }
    }
  },

  computed: {
    classing() {
      let activityClass = null;

      if (this.active !== null) {
        if (this.active === true) {
          activityClass = "active";
        }
        if (this.active === false) {
          activityClass = "inactive";
        }
      }

      return {
        [activityClass]: this.active !== null,
        breakWord: this.break,
        truncate: this.truncate,
        center: this.center
      };
    },

    styling() {
      return {
        fontSize: this.mixinSizing,
        lineHeight: "1.4em"
      };
    }
  }
};
</script>

<style lang="scss" scoped>
$title-indicator-width: 0.2em;
$title-color: $app-color--text-title;
$title-color-active: $app-color--hl;
$title-color-inactive: lighten(desaturate($title-color-active, 100%), 20%);

.base-title {
  //@extend %font--title; // TODO! OTA KÄYTTÖÖN KUN PLACEHOLDERIT TOIMII
  font-weight: 900;
  letter-spacing: 0.015em;
  overflow: hidden;
  color: $title-color;
  @extend %selected-text;

  &.active,
  &.inactive {
    position: relative;
    padding-left: 0.45em;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      //border-left: 6px solid $app-color--theme;
      border-left: $title-indicator-width solid $title-color-active; // TODO! MUUTA DYNAAMISEKSI
      height: 100%;
    }
  }
  //&.inactive::before { border-left: 6px solid $color--navy-blue-light; }
  &.inactive::before {
    border-left: $title-indicator-width solid $title-color-inactive;
  } // TODO! MUUTA DYNAAMISEKSI

  &.break-word {
    word-break: break-all;
  }
  //&.truncate { @extend %truncate; } // TODO! OTA KÄYTTÖÖN KUN PLACEHOLDERIT TOIMII

  //&.center { text-align: center; }
}
</style>
