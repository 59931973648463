<template>
  <div class="app-curtain" :style="styling">
    <base-icon app="constlet">constlet</base-icon>
  </div>
</template>

<script>
export default {
  name: 'appCurtain',

  async beforeCreate() {
    let asyncDone = false
    let minimumDone = false

    setTimeout(() => {
      minimumDone = true
    }, 1000)

    await this.$api.getData('content', {}, 'content', null, true)
    asyncDone = true

    let timer = setInterval(() => {
      if (asyncDone && minimumDone) {
        this.$store.dispatch('SET_STATE', { data: false, path: 'app.isLoading' })
        clearInterval(timer)
      }
    }, 100)

    this.$on('hook:beforeDestroy', () => {
      clearInterval(timer)
    })
  },

  computed: {
    styling() {
      return {
        zIndex: this.$store.state.app.ui.zIndex.appCurtain
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$curtain-color--bg: $app-color--main;

.app-curtain {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $curtain-color--bg;

  .base-icon {
    width: 30vmin;
    max-width: 140px;
    height: auto;
  }
}
</style>
