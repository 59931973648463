import store from '@/store/index'

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home'),
    beforeEnter: (to, from, next) => {
      if (process.env.NODE_ENV === 'production') {
        let host = window.location.host.split('.')
        let hostSuffix = host[host.length - 1]
        let inEnglish = hostSuffix === 'com' ? true : false

        store.dispatch('SET_STATE', { data: inEnglish, path: 'app.constlet.inEnglish' })
      }
      next()
    }
  },
  {
    path: '/fi',
    name: 'homeFi',
    component: () => import('@/views/Home'),
    beforeEnter: (to, from, next) => {
      store.dispatch('SET_STATE', { data: false, path: 'app.constlet.inEnglish' })
      next()
    }
  },
  {
    path: '/en',
    name: 'homeEn',
    component: () => import('@/views/Home'),
    beforeEnter: (to, from, next) => {
      store.dispatch('SET_STATE', { data: true, path: 'app.constlet.inEnglish' })
      next()
    }
  },
  {
    path: '/palaute',
    name: 'feedback',
    component: () => import('@/views/Feedback'),
    meta: {
      hideNavTop: true
    }
  },
  {
    path: '/appsprivacypolicy',
    name: 'privacyPolicy',
    component: () => import('@/views/PrivacyPolicy'),
    meta: {
      hideNavTop: true
    }
  },
  {
   path: '/appstermsconditions',
   name: 'TermsConditions',
   component: () => import('@/views/TermsConditions'),
   meta: {
     hideNavTop: true
   }
 },
  {
    path: '/404',
    name: 'error',
    component: () => import('@/views/Error'),
  },
  {
    path: '*',
    redirect: '/404'
  }
]

//export routes = routes
//module.exports = { default: routes } // ES6 Module Interoperability

/* export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home'),
    beforeEnter: (to, from, next) => {
      if (process.env.NODE_ENV === 'production') {
        let host = window.location.host.split('.')
        let hostSuffix = host[host.length - 1]
        let inEnglish = hostSuffix === 'com' ? true : false

        store.dispatch('SET_STATE', { data: inEnglish, path: 'app.constlet.inEnglish' })
      }
      next()
    }
  },

  {
    path: '/fi',
    name: 'homeFi',
    component: () => import('@/views/Home'),
    beforeEnter: (to, from, next) => {
      store.dispatch('SET_STATE', { data: false, path: 'app.constlet.inEnglish' })
      next()
    }
  },

  {
    path: '/en',
    name: 'homeEn',
    component: () => import('@/views/Home'),
    beforeEnter: (to, from, next) => {
      store.dispatch('SET_STATE', { data: true, path: 'app.constlet.inEnglish' })
      next()
    }
  },

  { path: '*', redirect: '/' }
]; */
