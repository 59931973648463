<template>
  <component
    :is="tag"
    class="base-text"
    :class="classing"
    :style="[styling, mixinMargins, mixinPaddings]"
  >
    <slot>{{ this.editableInnerContent }}</slot>
  </component>
</template>

<script>
// TODOS:
// PROP - MÄÄRITÄ NÄYTETTÄVIEN RIVIEN MAKSIMIMÄÄRÄ
// PROP - TURNCATE -> IMPLEMENTOI shave()

import { sizing, margins, paddings, dynamicStyleSet, editableContent } from '@/utils/mixins'

export default {
  name: "baseText",

  mixins: [sizing, margins, paddings, dynamicStyleSet, editableContent],

  props: {
    tag: {
      type: String,
      default: "p"
    },
    isHandle: {
      type: Boolean,
      default: false
    },
    weight: {
      type: Number
    },
    lorem: {
      type: Boolean,
      default: false
    },
    loremLength: Number
  },

  data() {
    return {
      mixinSizeCategories: { s: 7, m: 8, l: 9, xl: 10 },
      loremIpsum:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Volutpat odio facilisis mauris sit. Rhoncus dolor purus non enim praesent elementum facilisis leo. In eu mi bibendum neque. Phasellus vestibulum lorem sed risus ultricies tristique nulla. Quisque egestas diam in arcu cursus. Mattis rhoncus urna neque viverra justo. Iaculis nunc sed augue lacus viverra vitae congue eu. Sagittis aliquam malesuada bibendum arcu vitae elementum. Nibh mauris cursus mattis molestie a iaculis at erat. Risus quis varius quam quisque id diam."
    };
  },

  computed: {
    classing() {
      return { handle: this.isHandle }
    },

    styling() {
      return {
        fontSize: this.mixinSizing,
        lineHeight: "1.25em",
        fontWeight: this.weight ? this.weight : false
      };
    }
  }
};
</script>

<style lang="scss" scoped>
$text-color: $app-color--text-base;
$text-color-handle: $app-color--text-handle;

.base-text {
  overflow-y: hidden;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: $text-color;
  @extend %selected-text;
  &.handle { color: $text-color-handle; }
}
</style>
