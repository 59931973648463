<template>
  <div class="base-input" :class="classing" :style="[styling.root, mixinMargins]">

    <label>
      {{ this.label }}
      <span v-if="required"> (required)</span>
    </label>

    <div class="input-container">
      <input
        :type="type"
        :value="value"
        ref="input"
        v-bind="$attrs"
        v-on="listeners"
        :style="styling.input"
      >
      <base-icon v-if="iconLeft" @click="clickIcon('left')" :style="styling.iconLeft">{{ this.iconLeft }}</base-icon>
      <base-icon v-if="iconRight" @click="clickIcon('right')" :style="styling.iconRight">{{ this.iconRight }}</base-icon>
    </div>

    <slot></slot>
    <!-- <base-dropdown></base-dropdown> -->

  </div>
</template>

<script>
import { sizing, margins } from '@/utils/mixins'

export default {
  name: "baseInput",

  inheritAttrs: false,

  mixins: [sizing, margins],

  props: {
    type: {
      type: String,
      default: 'text',
      validator: prop => prop === 'text' || prop === 'password' || prop === 'email' || prop === 'number' || prop === 'search' || prop === 'tel' || prop === 'url'
    },
    value: String,
    label: String,
    required: Boolean,
    disabled: Boolean,
    focused: Boolean,
    iconLeft: String,
    iconRight: String,
  },

  data() {
    return {
      mixinSizeCategories: { s: 7, m: 8, l: 9, xl: 10 }, // customized, see: mixins.js
    }
  },

  watch: {
    focused: {
      immediate: true,
      handler () {
        if (this.focused) {
          this.$nextTick(() => this.focusInput())
        }
      }
    }
  },

  methods: {
    focusInput () {
      this.$refs.input.focus()
    },

    clickIcon(side) {
      if (side === 'right' && this.iconRight === 'close') {
        this.focusInput()
        this.$emit('input', '')
      } else this.$emit(side + 'IconClicked')
    }
  },

  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: event => { // override input from $listeners
          this.$emit('input', event.target.value)
        }
      }
    },

    classing() {
      return {
        required: this.required,
        disabled: this.disabled
      }
    },

    styling() {
      let inputHeight = 3.4 // = em
      let baseInputPadding = inputHeight * 0.2 // = em
      let leftIconSize = 1.8 // = em
      let rightIconSize = 1.1 // = em

      return {
        root: { fontSize: this.mixinSizing, },
        input: {
          height: inputHeight + 'em',
          paddingLeft:  this.iconLeft
                          ? (baseInputPadding * 1.8) + leftIconSize + 'em' // in theory, * 2, but eye tells better :)
                          : baseInputPadding + 'em',
          paddingRight: this.iconRight
                          ? (baseInputPadding * 1.8) + rightIconSize + 'em' // in theory, * 2, but eye tells better :)
                          : baseInputPadding + 'em'
        },
        iconLeft: {
          left: baseInputPadding + 'em',
          height: leftIconSize + 'em',
          width: leftIconSize + 'em'
        },
        iconRight: {
          right: baseInputPadding + 'em',
          height: rightIconSize + 'em',
          width: rightIconSize + 'em'
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
$input-color: $app-color--input;
$input-color--label: $app-color--input-label;
$input-color--border: $app-color--input-border;
$input-color--bg: $app-color--theme;
$input-color--placeholder: $app-color--input-placeholder;
$input-color--required: $app-color--error;
$input-color--icons: $input-color;

.base-input {
  width: 100%;
  
  &.disabled {
    @extend %disabled;
    opacity: 0.4;
  }

  label {
    display: block;
    margin-bottom: 0.3em;
    font-size: 0.9em;
    font-weight: 500;
    color: $input-color--label;
    span { color: $input-color--required; }
  }

  .input-container {
    font-size: inherit !important;
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @extend %clickable;
      color: $input-color--icons !important;
    }
  }
  input {
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    outline: 0;
    //border: 1px solid lighten(desaturate($input-color--border, 30%), 30%);
    border: 1px solid $input-color--border;
    border-radius: 3px;
    width: 100%;
    //padding: ; // See: this.styling.input
    background: $input-color--bg;
    color: $input-color !important;
    font-size: inherit;
    letter-spacing: 0.05em;
    //&:hover,
    //&:focus { border: 1px solid $input-color--border; }
    &::placeholder { // not supported in all browsers, but behaves as regular text content if not
      font-weight: 500;
      color: $input-color--placeholder;
    }
  }
}
</style>