<template>
  <div class="base-checkbox"
    role="checkbox"
    tabIndex="0"
    :aria-checked="checked"
    @click="toggle"
    @keydown.enter.prevent="toggle"
    @keydown.space.prevent="toggle"
    v-on="listeners"
  >
    <!-- <base-icon v-if="checked" width="100%">checked</base-icon> -->
    <base-icon v-if="checked">checked</base-icon>
    <!-- <span
      role="checkbox"
      tabIndex="0"
      :aria-checked="checked"
    ></span> -->
  </div>
</template>

<script>
export default {
  name: 'baseCheckbox',

  props: {
    value: {
      default: null,
      validator: prop => typeof prop === 'boolean' || prop === null
    },
    state: Boolean // if needed to be used without v-model
  },

  methods: {
    toggle () {      
      if (typeof this.$props.value !== 'object') { // typeof null returns 'object'
        this.$emit('input', !this.value)
      }
    }
  },

  computed: {
    checked () {
      if (this.state) {
        return this.state ? true : false
      } else {        
        return this.value ? true : false
      }
    },

    listeners() {
      return { ...this.$listeners }
    }
  }
}
</script>

<style lang="scss" scoped>
$base-checkbox--color-border: $app-color--theme;

.base-checkbox {
  outline: 0;
  //overflow: hidden;
  position: relative;
  //display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid $base-checkbox--color-border;
  //border-radius: 50%;
  border-radius: 2px;
  transition: background 0.3s ease;
  @extend %clickable;

  .base-icon {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 1px solid $base-checkbox--color-border;
  }
  
  &[aria-checked="true"] {
    border: 1px solid transparent;
    //background: $base-checkbox--color-border;
    /* &::after {
      content: "";
      position: absolute;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%, -50%);
      background: pink;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    } */
  }
}
</style>
