import state from './content.state'
import getters from './content.getters'
import mutations from './content.mutations'
import actions from './content.actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
