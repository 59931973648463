<template>
  <div class="base-toggle">
    <span
      role="checkbox"
      tabIndex="0"
      :aria-checked="checked"
      @click="toggle"
      @keydown.enter.prevent="toggle"
      @keydown.space.prevent="toggle"
    ></span>
    <label v-if="value && showText">{{ this.onText }}</label>
    <label v-else-if="!value && showText">{{ this.offText }}</label>
  </div>
</template>

<script>
export default {
  name: 'baseToggle',

  props: {
    value: Boolean,
    showText: Boolean,  // POISTUUKO KOKONAAN? (TURHA)
    onText: { // POISTUUKO KOKONAAN? (TURHA)
      type: String,
      default: 'ON'
    },
    offText: { // POISTUUKO KOKONAAN? (TURHA)
      type: String,
      default: 'OFF'
    }
  },

  methods: {
    toggle () {
      this.$emit('input', !this.value)
    }
  },

  computed: {
    checked () {
      if (this.value) {
        return 'true'
      } else {
        return 'false'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// MIKSEI SCOPED TOIMI TÄSSSÄ?????
// SELVITÄ JA MUUTA!!!
$toggle--color-bg: $app-color--hl;
$toggle--height: 20px;
$toggle-handle--height: calc(#{$toggle--height} * 0.9);

.base-toggle {
  flex-shrink: 0;
  position: relative;
  display:inline-block;
  height: $toggle--height;
  @extend %clickable;

  span {
    position: relative;
    display: inline-block;
    width: calc(#{$toggle--height} * 2);
    height: inherit;
    border-radius: calc(#{$toggle--height} / 2);
    background-color: lightgrey; //MUUTA!
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    outline: 0; // **TODO** kehitä tähän joku kauniimpi tapa
    &[aria-checked="true"] {
      background-color: $toggle--color-bg;
    }
    &:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      left: 0;
      width: $toggle-handle--height;
      height: $toggle-handle--height;
      // TAI:
      top: 50%;
      transform: translateY(-50%);
      left: calc((#{$toggle--height} * 0.1) / 2);
      border-radius: calc(#{$toggle--height} / 2);
      background-color: white;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
      transition: all 0.15s ease;
    }
    &[aria-checked="true"]:before {
      left: calc((100% - #{$toggle-handle--height}) - ((#{$toggle--height} * 0.1) / 2));
    }
  }

  label {
    position: absolute;
    top: calc(100% + 3px);
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.65rem;
    color: grey;
  }
}

</style>
