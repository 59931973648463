<template>
  <div class="base-div" :style="[mixinMargins, mixinPaddings]">
    <slot></slot>
  </div>
</template>

<script>
import { margins, paddings } from '@/utils/mixins'

export default {
  name: "baseDiv",

  mixins: [ margins, paddings]

  /* props: {
    width: String,
    height: String,
    overflow: {
      type: String,
      validator: prop => {
        return ['x', 'y', 'xy'].indexOf !== -1
      }
    }
  }, */

  /* computed: {
    styling() {
      return {
        width: this.width,
        height: this.height,
        overflowX: this.overflow === 'x' || this.overflow === 'xy' ? 'auto' : 'visible',
        overflowY: this.overflow === 'y' || this.overflow === 'xy' ? 'auto' : 'visible'
      }
    }
  } */
}
</script>

<style lang="scss" scoped>
.base-div {
}
</style>
