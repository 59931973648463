import firebase from "@/plugins/API/firebase/firebaseInit";
import store from "@/store";
import dataParser from "./parsers/data_parser";
import { load } from 'recaptcha-v3'

const firestore = firebase.firestore()
const storageRef = firebase.storage().ref();
let defers = {}
setFirebaseAuth()

const getData = (collection, query, storePath, objectName, asObject) => {
  const queryObject = query;
  return new Promise(resolve => {
    let database = firestore.collection(collection);
    if (query.document) {
      database = database.doc(query.document)
      database.get().then(doc => {
        resolve(doc.data())
      })
    } else {
      database.get().then(querySnapshot => {
        const parsed = dataParser.parseData(querySnapshot, objectName, asObject);
        if (storePath) {
          store.dispatch('SET_STATE', { data: parsed, path: storePath })
          resolve();
        }
        resolve(parsed);
      });
    }
  });
}
const addData = (collection, dataObject) => {
  return new Promise((resolve, reject) => {
    const database = firestore.collection(collection);
    database.add(dataObject)
    .then((docID) => {
      console.log("Document successfully written!");
      resolve(docID);
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      reject(error);
    });
  });
}
const setData = (collection, doc, dataObject, merge) => {
  return new Promise((resolve, reject) => {
    const database = firestore.collection(collection);
    database.doc(doc).set(dataObject, { merge: merge })
    .then(() => {
      console.log("Document successfully written!");
      resolve();
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      reject(error);
    });
  });
}
const updateData = (collection, doc, dataObject) => {
  return new Promise((resolve, reject) => {
    const database = firestore.collection(collection);
    database.doc(doc).update(dataObject)
    .then(() => {
      console.log("Document successfully updated!");
      resolve();
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      reject(error);
    });
  });
}
const uploadDataToStorage = (dataURL, path) => {
  return new Promise((resolve, reject) => {
    const ref = storageRef.child(path);

    ref.putString(dataURL, 'data_url')
    .then((snapshot) => {
      resolve('success');
    })
    .catch((err) => {
      reject(err);
    });
  })
}
const getFilesFromStorage = path => {
  return new Promise((resolve, reject) => {
    const storageRef = firebase.storage().ref(path);
    let files = []

    storageRef.listAll().then(result => {
      result.items.forEach(imageRef => {
        files.push(imageRef.fullPath)
      });
      resolve(files)
    }).catch(error => {
      reject(error)
    });
  });
}
const deleteFileFromStorage = filePath => {
  const desertRef = storageRef.child(filePath); // e.g. images/test.png

  desertRef.delete().then(function() {
    // File deleted successfully
  }).catch(function(error) {
    // Uh-oh, an error occurred!
  });
}

const login = (email, password) => {
  return new Promise((resolve, reject) => {
    addDefer('logging')
    firebase.auth().signInWithEmailAndPassword(email, password)
    .then(async () => {
      await getDefer('logging')
      resolve('logged in')
    })
    .catch((error) => {
      reject(error)
      console.log(error.code,  error.message)
    });
  })
}
const logout = () => {
  firebase.auth().signOut()
}
const isLogged = () => {
  return store.state.auth.email
}
const isAdmin = () => {
  return store.state.auth.email && store.state.auth.admin
}
const createCaptchaToken = () => {
  return new Promise((resolve) => {
    load(store.state.content.meta.recaptcha.sitekey).then((recaptcha) =>{
      recaptcha.execute('validate').then((token) => {
        store.dispatch('SET_STATE', { data: token, path: 'app.validateToken' })
        resolve()
      });
    })
  })
}
const sendValidateMessage = () => {
  return new Promise((resolve, reject) => { 
    let data = {
      token: store.state.app.validateToken
    }
    const xmlhttp = new XMLHttpRequest();
    const theUrl = 'https://us-central1-constlet.cloudfunctions.net/recaptchaValidate';
    xmlhttp.open("POST", theUrl);
    xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xmlhttp.send(JSON.stringify(data));
    xmlhttp.onreadystatechange = async () => {
      if (xmlhttp.readyState == XMLHttpRequest.DONE) {
        const response = JSON.parse(xmlhttp.responseText)
        if (!response.success) {
          reject(response)
        }

        if (response.score) {
          const score = parseFloat(response.score)
          if (score > store.state.content.meta.recaptcha.threshold) {
            resolve(true)
          }
        }
        resolve(false)
      }
    }
  })
}
const reCaptchaValidate = () => {
  return new Promise(async (resolve) => {
    let result = await sendValidateMessage()
    .catch(async (err) => {
      await createCaptchaToken()
      result = await sendValidateMessage()
    })
    resolve(result)
  })
}

function setFirebaseAuth() {
  firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      const userdata = await getData('users', { document: user.uid })
      store.dispatch('SET_STATE', { data: userdata, path: 'auth' })
      getDefer('logging').resolve()
    } else {
      store.dispatch('SET_STATE', { data: {}, path: 'auth' })
    }
  })
}
function addDefer(name) {
  let defer = () => {
    let res, rej
    let promise = new Promise((resolve, reject) => {
      res = resolve;
      rej = reject;
    })
    promise.resolve = res;
    promise.reject = rej;
  
    return promise;
  }
  defers[name] = defer()
}
function getDefer(name) {
  return defers[name] || { resolve: () => {}, reject: () => {} }
}
export default {
  getData,
  addData,
  setData,
  updateData,
  uploadDataToStorage,
  getFilesFromStorage,
  deleteFileFromStorage,
  login,
  logout,
  isLogged,
  isAdmin,
  addDefer,
  getDefer,
  reCaptchaValidate,
  createCaptchaToken
}