import state from "./modals.state";
import getters from "./modals.getters";
import mutations from "./modals.mutations";
import actions from "./modals.actions";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
