<template>
  <button
    v-on="listeners"
    class="button"
    :class="classing"
    :style="[styling, mixinMargins]"
  >
    <base-icon v-if="icon" :icon="icon"></base-icon>
    <slot>baseButton</slot>
  </button>
</template>

<script>
import { sizing, margins } from "@/utils/mixins";

export default {
  name: "baseButton",

  mixins: [sizing, margins],

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    styleSet: {
      // TODO!: PARANNA JA TEE TÄMÄ GENEERISESTI KAIKKIIN BASE-KOMPONENTTEIHIN
      type: Number,
      default: 0
    },
    rounded: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    empty: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    stretch: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    listeners() {
      return { ...this.$listeners };
    },

    classing() {
      // let externalApps = ['discord', 'facebook', 'twitch', 'twitter', 'youtube']
      let classes = {
        [`style-${this.styleSet}`]: true,
        [`${this.icon}`]: this.icon ? this.icon : false,
        rounded: this.rounded,
        secondary: this.secondary,
        disabled: this.disabled,
        empty: this.empty,
        center: this.center,
        stretch: this.stretch
      };

      return classes;
    },

    styling() {
      return {
        fontSize: this.mixinSizing,
        lineHeight: "1.4em"
      };
    }
  }
};
</script>

<style lang="scss" scoped>
// HUOM! sijoita icon nappiin absolutena (muuten ei vaan onnistu nätisti) ja säädä padding-left:it kuntoon napin koon mukaan erikseen
// HUOM! mieti, onko .inactive ollenkaan tarpeellinen??? (/ pärjäisikö pelkällä .disabled:lla?!)

$button-height: 2.5em !default;
$button-color: $app-color--button !default;
$button-color--secondary: $app-color--button-secondary !default;
$button-color--disabled: lighten(desaturate($button-color, 30%), 80%);
$button-color--text: $app-color--button-text;
$disabled-button-opacity: 0.4;

.button {
  display: flex;
  align-items: center;
  position: relative; // for the icons
  padding: 0.4em 1em;
  height: $button-height;
  //line-height: ; // see: this.styling()
  //font-size: ; // see: this.styling()
  //text-transform: capitalize;
  font-weight: 700;
  @extend %clickable;
  user-select: none;
  outline: 0;
  -webkit-tap-highlight-color: transparent; // gets rid of tap active state (= non-standard across browsers)
  &.center {
    margin: 0 auto;
  }

  svg {
    width: calc(#{$button-height} * 0.7);
    height: calc(#{$button-height} * 0.7);
    margin-right: 0.5rem;
  }
  &.discord { background-color: colorlist("custom", "discord") !important; }
  &.facebook { background-color: colorlist("custom", "facebook") !important; }
  &.twitch { background-color: colorlist("custom", "twitch") !important; }
  &.twitter { background-color: colorlist("custom", "twitter") !important; }
  &.youtube { background-color: colorlist("custom", "youtube") !important; }

  &.disabled {
    pointer-events: none !important;
    outline: 0;
    background: $button-color--disabled !important;
    border: 1px solid $button-color--disabled !important;
    &:focus {
      outline: 0;
    }
  }
  &.rounded { border-radius: calc(#{$button-height} / 2) !important; }
  &.stretch { width: 100%; justify-content: center; }

  &.style-0 {
    //border: none;
    border: 1px solid $button-color;
    border-radius: 3px;
    background-color: $button-color;
    color: $button-color--text;
    &:hover {
      color: $button-color !important;
      background: $button-color--secondary;
    }
    //&:active {} // LISÄÄ MYÖHEMMIN
    /* &.disabled {
      background-color: $button-color--disabled !important;
      color: $button-color--text-disabled !important;
    } */
    &.secondary {
      background-color: $button-color--secondary;
    }
    &.empty {
      border: 1px solid $button-color;
      background-color: $button-color--text;
      color: $button-color;
      &:hover {
        opacity: 1 !important;
        background-color: $button-color;
        color: $button-color--text !important;
      }
      &.secondary {
        border: 1px solid $button-color--secondary;
        background-color: $button-color--text;
        color: $button-color--secondary;
        &:hover {
          background-color: $button-color--secondary;
          color: $button-color--text;
        }
      }
      /* &.disabled {
        border: 1px solid $button-color--disabled;
        background-color: rgb(255, 255, 255) !important;
        color: $button-color--disabled;
      } */
    }
  }
}
</style>
