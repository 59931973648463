<template>
  <div id="nav-top" class="nav-top" :style="styling">

    <base-flex :full="true" justify="between">
      <base-flex class="logo-container" @click.native="moveTo({ id: 'constlet-home' })" center="y">
        <base-icon app="constlet" size="xl">constlet</base-icon>
        <base-icon v-if="navTopHandling.logoText" class="logo-text" :size="55" mL="s">constlet-text</base-icon>
      </base-flex>

      <base-flex center="y">
        <base-title tag="h1" style="position: absolute; opacity: 0; pointer-events: none;">Tailored digital experiences with usability and design at core</base-title>
        <template v-for="link in navLinks">
          <base-text
            v-if="link.title !== 'home'"
            :key="link.title"
            @click.native="moveTo(link)"
            :size="navTopHandling.text"
            :mL="navTopHandling.margin"
            style="color: white;"
          >{{ inEnglish ? link.title : link.titleFi }}</base-text>
        </template>
      </base-flex>
    </base-flex>

    <base-button
      class="toggle"
      @click="toggleLocalization"
      size="s"
    >
      {{ this.inEnglish ? 'FI' : 'EN' }}
    </base-button>
    
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ScrollMagic from 'scrollmagic'

export default {
  name: "navTop",

  mounted() {
    const controller = new ScrollMagic.Controller()

    const scene = new ScrollMagic.Scene({ duration: 0, offset: 70, triggerHook: 'onEnter' })
    .setClassToggle('#nav-top', 'visible')
    .addTo(controller)
  },

  computed: {
    ...mapState('app', ['window', 'constlet']),

    navLinks() {
      let constlet = this.$store.state.app.constlet
      let sections = this.$store.state.app.constlet.sections

      return constlet.sections
    },

    inEnglish() {
      return this.$store.state.app.constlet.inEnglish
    },

    styling() {
      let padding = 0.9

      if (this.window.width > 1000) { padding = 2.5 }
      else if (this.window.width > 600) { padding = 1.6 }

      return {
        height: this.$store.state.app.ui.navTopHeight + "px",
        paddingLeft: padding + 'rem',
        paddingRight: padding + 'rem'
      }
    },

    navTopHandling() {
      let breakpoint = 500
      return {
        logoText: this.window.width > 560 ? true : false,
        text: this.window.width < breakpoint ? 6.5 : 9,
        margin: this.window.width < breakpoint ? 5.5 : 10
      }
    }
  },

  methods: {
    toggleLocalization() {
      let inEnglish = this.$store.state.app.constlet.inEnglish
      let path      

      if (window.location.pathname.split('').length > 1) { // = '/fi' or '/en'      
        path = '/'
      } else { // = '/'
        if (process.env.NODE_ENV === 'production') {
          let host = window.location.host.split('.')
          let hostSuffix = host[host.length - 1]

          if (hostSuffix === 'com') { path = '/fi' }
          else { path = '/en' }
        } else { path = '/fi' }
      }

      this.$store.dispatch('SET_STATE', { data: !inEnglish, path: 'app.constlet.inEnglish' })
      this.$router.push(path)
    },

    moveTo(link) {
      const section = document.getElementById(link.id)
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
    }
  }
};
</script>

<style lang="scss" scoped>
$navTop-color: $app-color--theme;
$navTop-color--bg: $app-color--main;

.nav-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  background: transparent;
  transition: background 0.4s ease;
  &.visible { background: $navTop-color--bg; }

  .logo-container { overflow: hidden; }
  .logo-text { color: $navTop-color; }

  .base-icon { @extend %clickable; }
  .base-text {
    color: $navTop-color;
    font-weight: 700;
    letter-spacing: 0.03rem;
    @extend %clickable;
  }

  .toggle {
    position: absolute;
    top: calc(100% + 6px);
    right: 6px;
  }
}
</style>
