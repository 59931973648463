const parseData = (data, objectName, asObject) => {

  if (objectName) {
    let parsed = {}
    data.forEach(doc => {
      let dataObject = doc.data();
      let name = dataObject[objectName]
      parsed[name] = dataObject;
    });
    return parsed;
  } else if (!asObject) {
    let parsed = []
    data.forEach(doc => {
      let dataObject = doc.data();
      parsed.push(dataObject);
    });
    return parsed;
  } else if (asObject) {
    let parsed = {}
    data.forEach(doc => {
      parsed[doc.id] =  doc.data();
    });
    return parsed;
  }
}


export default {
  parseData
}