import Vue from "vue"
import App from "@/App.vue"
import router from "@/router/index"
import store from "@/store/index"
import "@/components/base/_globals" // Global base components
import "@/registerServiceWorker"
import API from "@/plugins/API"
import VueHead from 'vue-head'

Vue.config.productionTip = false

Vue.use(VueHead)
Vue.use(API, "firebase")

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
