<template>
  <div class="base-divider" :style="[mixinMargins, mixinPaddings]">
    <div :style="styling"></div>
  </div>
</template>

<script>
// LISÄÄ MAHDOLLISUUS VALIITA PYSTYSUUNTAINEN DIVIDER???

import { margins, paddings } from '@/utils/mixins'

export default {
  mixins: [margins, paddings],

  props: {
    maxWidth: {
      type: String,
      default: '100%'
    }
  },

  computed: {
    styling () {
      return { maxWidth: this.maxWidth }
    }
  }
}
</script>

<style lang="scss" scoped>
$divider-color: darken($app-color--theme, 40%);

.base-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4px;
  div {
    height: 1.1px;
    width: 100%;
    background: $divider-color;
  }
}
</style>
