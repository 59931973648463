<template>
  <div ref="bg" class="base-bg" :class="classing" :style="styling">
    <slot></slot>
  </div>
</template>

<script>
// TODOS:
// YLEISET: MIETI VOIKO TEHDÄ NIIN ETTÄ VOI ANTAA <img>:N SLOTTIIN

export default {
  name: "baseBg",

  props: {
    source: {
      type: String,
      //required: true,
      default:'https://storage.googleapis.com/constlet.appspot.com/images/bg_default.jpg' // tänne store.state.api.imageURL
    },
    height: {
      type: String,
      default: '70vmin'
    },
    fit: {
      type: String,
      default: 'cover',
      validator: prop => prop === 'cover' || prop === 'contain'
    },
    parallax: Boolean,
    parallaxAmount: {
      type: Number,
      default: 0.2
    },
    steady: Boolean, // Background stays stationary despite of scrolling
    full: Boolean
  },

  data() {
    return { offsetY: null }
  },

  created() {
    this.handleWindowScroll()

    window.addEventListener("scroll", this.handleWindowScroll);
    this.$on("hook:beforeDestroy", () => {
      window.removeEventListener("scroll", this.handleWindowScroll);
    });
  },

  methods: {
    handleWindowScroll() {
      this.offsetY = window.pageYOffset
    }
  },

  computed: {
    classing() {
      return {
        parallax: this.parallax,
        steady: this.parallax && this.steady ? this.steady : false,
        full: this.full
      }
    },
    styling() {
      return {
        height: this.full ? 'initial' : this.height,
        backgroundImage: `url(${this.source})`,
        backgroundSize: this.steady ? 'cover' : this.fit, // 'cover' / 'contain'
        backgroundPositionY: this.parallax && !this.steady ? this.offsetY * this.parallaxAmount + 'px' : false,
        backgroundAttachment: this.steady ? 'fixed' : 'initial'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.base-bg {
  z-index: -1;
  position: relative;
  width: 100%;
  min-height: 40vmin;
  background-repeat: no-repeat;
  background-position: center center;
  //background: lightblue;

  &.full {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: auto;
  }
}
</style>
