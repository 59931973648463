export default {
  //serverURL: 'https://espotapi.appspot.com',
  appURL: process.env.NODE_ENV === 'production'
    ? 'https://google.com'
    : `${window.location.protocol}//${window.location.host}`,
  imageURL: 'https://storage.googleapis.com/constlet.appspot.com/images/',
  storageURL: 'https://storage.googleapis.com/constlet.appspot.com/'
  //avatars: '',
  //bg: '',
  //content: '',
  //icons: ''
};
