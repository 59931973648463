
<template>
  <div id="app" :class="appClassing">
    <app-curtain v-if="displayControl.appCurtain"></app-curtain>
    <app-handler></app-handler>

    <!-- TÄSSÄ V-IF PITÄISI SAADA POSITETTUA ETTÄ CURTAINILLA OLISI OIKEASTI VIRKAA!!! -->
    <router-view v-if="!displayControl.appCurtain"></router-view>

    <nav-top v-if="displayControl.navTop"></nav-top>

    <content-handler v-if="displayControl.contentHandler"></content-handler>
    <error-handler v-if="displayControl.errorHandler"></error-handler>
    <modal-handler v-if="displayControl.modalHandler"></modal-handler>

  </div>
</template>

<script>
import navTop from '@/components/navTop'
import appHandler from '@/components/appHandler'
import appCurtain from '@/components/appCurtain'

export default {
  name: 'app',

  components: {
    navTop,
    appHandler,
    appCurtain,
    contentHandler: () => import('@/components/contentHandler'),
    errorHandler: () => import('@/components/errorHandler'),
    modalHandler: () => import('@/components/modalHandler')
  },

  computed: {
    displayControl() { //HUONO NIMI -> MUUTA?
      return {
        appCurtain: this.$store.state.app.isLoading,
        navTop: !this.$route.meta.hideNavTop,
        contentHandler: this.$store.state.modals.showContentHandler,
        errorHandler: this.$store.state.app.errors.length >= 1,
        modalHandler: this.$store.state.modals.showModal
      }
    },

    appClassing() {
      let darkMode = this.$store.state.app.ui.darkMode
            
      return {
        //'dark-mode': darkMode
      }
    }
  }
}
</script>

<style lang="scss">
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.25em;
  background-color: $app-color--bg;
}

ul, li { margin: 0; }

input { font-family: $font-set--sans; }

#app {
  color: $app-color--text-base;
  font-family: $font-set--sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
