<template>
  <div class="base-hero" :style="styling.root">
    <slot>
      <base-title class="lightened" size="xl">Otsikko</base-title>
      <base-text class="lightened" :lorem=true :weight=700 :mT=10 :mB=20></base-text>
      <base-button size="xl">nappi</base-button>
    </slot>
    <!-- <div
      ref="wrapper"
      class="wrapper"
      :class="{ wide: wide }"
      :style="styling.wrapper"
    >

      <div class="primary">
        <slot name="primary">
          <base-title class="lightened" size="xl">Otsikko</base-title>
          <base-title class="lightened" size="xl">Otsikko</base-title>
          <base-text class="lightened" :lorem=true :weight=700 :mT=10 :mB=20></base-text>
          <base-button size="xl">nappi</base-button>
        </slot>
      </div>
      <div class="secondary">
        <slot name="secondary">
        </slot>
      </div>
      <div class="full">
        <slot></slot>
      </div>
      
      <base-flex :full=true :wrap=true>
        <div class="boxi">
          <base-title>Otsikko</base-title>
          <base-text :lorem=true></base-text>
        </div>
        <div class="boxi kaksi">
          <base-title>Otsikko2</base-title>
          <base-text :lorem=true></base-text>
        </div>
      </base-flex>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "baseHero",

  props: {
    height: {
      type: String,
      //default: "70vmin"
    }
  },

  data() {
    return {
      wide: false
    }
  },

  /* mounted() {
    this.handleResize()

    window.addEventListener('resize', this.handleResize)
    this.$on('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.handleResize)
    })
  }, */

  computed: {
    styling() {
      let ui = this.$store.state.app.ui

      return {
        root: {
          height: this.height,
          navTop: ui.navTopHeight + 'px'
        },
        wrapper: { maxWidth: ui.contentMaxWidth ? (ui.contentMaxWidth * 0.8) + 'px' : 'none' }
        //height: this.height
      };
    }
  },

  /* methods: {
    handleResize() {
      let width = this.$refs.wrapper.offsetWidth

      if (width < 600) {
        this.wide = false
      } else {
        this.wide = true
      }
    }
  } */

  // IN-COMPONENT ROUTE GUARDS:
  // See: https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
  // beforeRouteEnter (to, from, next) {}
  // beforeRouteUpdate (to, from, next) {}
  // beforeRouteLeave (to, from, next) {}
};
</script>

<style lang="scss" scoped>
.base-hero {
  position: relative;
  //padding: 6rem 4rem;
  //padding-top: calc(14vmin + 80px);
  //height: ; // see: this.styling.height

  &::v-deep * { color: white !important; }

  .wrapper {
    position: relative;
    //height: 500px; // POISTUU KOKONAAN?!?!
    margin: 0 auto;
    //background: lime;
    display: grid;
    //grid-gap: 5vmin;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "primary"
      "secondary";
    
    &.wide {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      /* grid-template-areas:
        "primary" "secondary"; */
    }

    .primary {
      //grid-template-columns:
    }
    .secondary {
      //grid-template-columns:
    }
    .full {
      //grid-template-columns:
    }
  }

  .lightened { color: white !important; }

  /* .boxi {
    overflow: hidden;
    //background: yellow;
    //width: 50%;
    //min-width: 300px;
    
    //margin: 0 3rem;
  } */

}
</style>
