import state from "./api.state";
import getters from "./api.getters";
import mutations from "./api.mutations";
import actions from "./api.actions";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
