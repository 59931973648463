export default {
  isLoading: true,
  //bigDataLoaded: false,
  window: {
    width: null,
    height: null
    //offsetY: null // TARVIIKO OLLENKAAN?! (<base-bg>:n parallax tökki vähän storen kautta tuotaessa)
  },
  ui: {
    preventBodyScrolling: false,
    breakpoints: [420, 768], // default 2, max 3 (if unique layout for super wide screen is provided)
    darkMode: false,
    navTopHeight: 70,
    //navTopDisplayed: true,
    //navLeftWidth: 250,
    //navLeftDisplayed: false,
    contentMaxWidth: 1000,
    contentPadding: 32, // 16px = 1rem
    baseDimension: 0.125, // (rem)
    zIndex: {
      appCurtain: 10000,
      errorHandler: 1000,
      contentHandler: 100,
      navTop: 10
    }
  },
  styleSet: { // for project related styling of base components (0 = default)
    baseButton: 0,
    baseDivider: 0,
    baseDropdown: 0,
    baseInput: 0,
    baseLink: 0,
    baseLoader: 0,
    baseSelect: 0,
    baseText: 0,
    baseTextarea: 0,
    baseTitle: 0,
  },
  externalApps: [
    //{ title: 'Discord', icon: 'discord', link: '' },
    //{ title: 'Facebook', icon: 'facebook', link: '' },
    //{ title: 'Instagram', icon: 'instagram', link: '' },
    //{ title: 'Mixer', icon: 'mixer', link: '' },
    //{ title: 'Twitch', icon: 'twitch', link: '' },
    //{ title: 'Twitter', icon: 'twitter', link: '' },
    //{ title: 'Youtube', icon: 'youtube', link: '' },
    //{ title: 'LinkedIn', icon: 'linkedin', link: '' }
  ],
  official: {
    //appName: 'appName',
    companyName: "Constlet",
    emailPrefix: "team",
    emailSuffix: "com",
    phone: "+358 50 4110 936"
    //tosUpdated: '',
    //ppUpdated: '',
    //cpUpdated: '',
    //apUpdated: '',
    //tos: ``,
    //pp: ``,
    //cp: ``,
    //ap: ``
  },
  errors: [
    // { type: 404, id: 1, text: '404 - page not found' }
  ],
  constlet: {
    sections: {
      home: { id: 'constlet-home', title: 'home', titleFi: 'alkuun' },
      services: { id: 'constlet-services', title: 'services', titleFi: 'palvelut' },
      process: { id: 'constlet-process', title: 'process', titleFi: 'prosessi' },
      references: { id: 'constlet-references', title: 'references', titleFi: 'referenssit' },
      contact: { id: 'constlet-contact', title: 'contact', titleFi: 'ota yhteyttä' }
    },
    sectionPaddingTop: 70,
    inEnglish: true, // false = finnish

    /* textContent: {
      en: { // in english
        language: 'en',
        hero: {
          title: 'Tailored digital experiences with usability and design at core',
          text: 'We are a Finnish software and design company that specialize in extraordinary audiovisual experiences that are tailored to your needs and insights',
          button: 'Hire us'
        },
        services: {
          section: 'Services',
          web: 'Websites and cross platform applications with a beautiful design and simple usability crafted to meet your needs and insights.',
          design: 'Logo, layout and video graphics personalized to service your look and feel with an aim to make a distinctive statement about you.',
          audio: 'Music composition and sound effects to bring together the feel that you want to create for your project, whether to support visuals or create ambience.',
          video: 'Music composition and sound effects to bring together the feel that you want to create for your project, whether to support visuals or create ambience.'
        },
        process: {
          section: 'Process',
          content: [
            { title: 'Understanding', text: 'We study and identify the key areas from your request summary given the budget.' },
            { title: 'Refinement', text: 'We create use case statements that crystallize the usage flow and agree on these with you.' },
            { title: 'Crafting', text: 'Our design and/or creative will be based on these statements - we will not try and fit your project into a standard template.' },
            { title: 'Implementation', text: 'We implement the project and undergo agreed number of reviews on the project with you prior to handoff.' },
            { title: 'Release', text: 'Final release in the agreed environment or format ensuring simplicity and cost efficiency for you.' }
          ]
        },
        references: {
          section: 'References'
        },
        contact: {
          section: 'Contact',
          name: 'Tell us your name...',
          email: '...and email',
          budget: 'What\'s your budget?',
          summary: 'Summarize your idea',
          button: 'Send'
        },
        footer: {
          aboutTitle: 'About us',
          aboutText: 'We are a team of Finns with a passion for beautiful visual and musical experiences, software that is easy to use where this is fundamentally applied to the logic that serves the purpose of the software. We want to excel and create an experience for our customers that surprises and satisfies in these aspects.'
        }
      }      
    } */
  }
};
